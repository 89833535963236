import React from 'react';

import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonText,
    RefresherEventDetail,
    useIonViewDidEnter
} from '@ionic/react';

import { useTranslation } from 'react-i18next';

import Header from './layout/Header';
import MainBannerSlide from './components/MainBannerSlide';
import useToggleTabBar from '../../components/hooks/useToggleTabBar';
import { toAbsolutePublicUrl } from '../../helpers/AssetHelpers';
import { useLoadingSpinnerContext } from '../../contexts/LoadingSpinnerProvider';
import useGoTo from '../../components/hooks/useGoTo';

import './HomePage.css';

/**
 * 
 * @returns 
 */
const HomePage: React.FC = () => {
    /**
     * 
     */
    const toggleTabBar = useToggleTabBar();

    /**
     * 
     */
    useIonViewDidEnter(() => {
        toggleTabBar(true);
    });

    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const goTo = useGoTo();

    /**
     * 
     */
    const { setShowSpinner } = useLoadingSpinnerContext();

    /**
     * 
     * @param event 
     */
    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setTimeout(() => {
            // Any calls to load data go here
            window.location.reload();
            event.detail.complete();
        }, 2000);
    };
    return (
        <IonPage id='homePage'>
            <Header />
            <IonContent
                id='homePageContent'
                className='ion-padding'
            >
                <IonRefresher
                    slot='fixed'
                    onIonRefresh={handleRefresh}
                >
                    <IonRefresherContent />
                </IonRefresher>
                <div className='wrapper main-banner-slide-wrapper mt-5 mb-10'>
                    <MainBannerSlide />
                </div>
                <div className='wrapper section-title-wrapper'>
                    <IonText className='section-title title-color font-xxl'>{t('Que voulez-vous')} ?</IonText>
                </div>
                <div className='wrapper'>
                    <IonGrid className="">
                        <IonRow>
                            <IonCol size="12" size-sm="6" size-md='6' size-lg='6' size-xl='6'>
                               <div className='home-menu-item'>
                                    <IonCard
                                        className="card home-menu-item-card"
                                        style={{backgroundImage:`url(${toAbsolutePublicUrl('/media/images/illustrations/acheter-un-box.jpg')}`}}
                                        button={true}
                                        onClick={() => {
                                            setShowSpinner(true);
                                            setTimeout(() => {
                                                setShowSpinner(false);
                                            }, 1000);
                                            goTo(`/box-types/`, 'forward', 'push');
                                        }}
                                    >
                                        <IonCardContent className="card-body">
                                            <IonCardTitle className='card-title'>{t('Acheter un Box')}</IonCardTitle>
                                        </IonCardContent>
                                    </IonCard>
                                </div>
                            </IonCol>
                            <IonCol size="12" size-sm="6" size-md='6' size-lg='6' size-xl='6'>
                                <IonCard
                                    className="card home-menu-item-card"
                                    style={{backgroundImage:`url(${toAbsolutePublicUrl('/media/images/illustrations/acheter-au-detail.jpg')}`}}
                                    button={true}
                                    onClick={() => {
                                        setShowSpinner(true);
                                        setTimeout(() => {
                                            setShowSpinner(false);
                                        }, 1000);
                                        goTo('/categories/', 'forward', 'push');
                                    }}
                                >
                                    <IonCardContent className="card-body">
                                        <IonCardTitle className='card-title'>{t('Acheter au détail')}</IonCardTitle>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
                <div className="elfsight-app-ff865515-63a6-491f-97f8-c6fa0c5b8afa"></div>
            </IonContent>
        </IonPage>
    );
};

export default HomePage;
