import React from "react";

import CartBoxList from "./components/boxes/CartBoxList";
import CartSliceList from "./components/slices/CartSliceList";
import OrderSummary from "./components/order/OrderSummary";
import { useCartMenuTabsContext } from "./components/contexts/CartMenuTabsProvider";
import { useUserInfosContext } from "../../contexts/UserInfosProvider";
import Unavailable from "./Unavailable";

import './ContentBody.css';

/**
 * 
 * @returns 
 */
const ContentBody: React.FC = () => {
    /**
     * 
     */
    const { logged } = useUserInfosContext();

    /**
     * 
     */
    const { currentMenuTab } = useCartMenuTabsContext();

    if(logged){

        return (
            <div className="content-body">
            {
                getTabView(currentMenuTab)
            }
            </div>
        );
    }
    else {
        return(
            <Unavailable />
        );
    }
};

/**
 * 
 * @param index 
 * @returns 
 */
function getTabView(index: number) {

    switch(index){
        case 1:
            return <CartBoxList />;
        case 2:
            return <CartSliceList />;
        case 3:
        default:
            return (
                <div className="order-summary-wrapper pt-5">
                    <OrderSummary />
                </div>
            );
    }
}

export default ContentBody;
