import React, { useCallback, useContext } from "react";

import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle
} from "@ionic/react";

import { useTranslation } from "react-i18next";

import { CategoryProps } from "../categories/Category";
import { formatNumber } from "../../helpers/AssetHelpers";
import SlicesHandlersContext from "../../contexts/SlicesHandlersContext";
import { useSlicePriceVisibilityContext } from "../../contexts/SlicePriceVisibilityProvider";
import { useConnectionReminderAlertContext } from "../../contexts/ConnectionReminderAlertProvider";
import MinusIcon from "../icons/svg/MinusIcon";
import PlusIcon from "../icons/svg/PlusIcon";

import './Slice.css';

/**
 * 
 */
export interface SliceProps {
    id: number;
    category: CategoryProps;
    wording: string;
    price: number;
    weight: number;
    illustration: string;
    available_in_box: boolean;
}

/**
 * 
 * @param slice_props 
 * @returns 
 */
const Slice: React.FC<SliceProps> = (slice_props : SliceProps) => {
    /**
     * 
     */
    // const [quantity,set]
    // const { addToCart, removeFromCart,getQuantity } = useBoxTypeContext();
    const { add, remove, getQuantity } = useContext(SlicesHandlersContext);

    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const { setCanPresent } = useConnectionReminderAlertContext();

    /**
     * 
     */
    const handleAddToCart = useCallback(() => {
        const token = localStorage.getItem('token');
        if(!token){
            setCanPresent(true);
        }
        else {
            const itemToAdd = { id: slice_props.id, quantity: 1 };
            add(itemToAdd);
        }
    }, [add, setCanPresent, slice_props.id]);

    /**
     * 
     */
    const handleRemove = useCallback(() => {
        remove(slice_props.id);
    }, [remove, slice_props.id]);

    /**
     * 
     */
    const {showPrice} = useSlicePriceVisibilityContext();

    /**
     * 
     */
    return (
        <IonCard className="slice-widget card translucent-style">
            <div className="slice-image-wrapper">
                <img
                    className="slice-image img-fluid"
                    // src={toAbsolutePublicUrl('/media/images/illustrations/slices/' + slice_props.illustration)}
                    src={slice_props.illustration}
                    alt={t(slice_props.wording)}
                />
            </div>
            <IonCardContent className="slice-widget-content card-body">
                <div className="slice-infos">
                    <div className="slice-title-wrapper">
                        <IonCardTitle className="slice-title card-title title-color font-md">{t(slice_props.wording)}</IonCardTitle>
                    </div>
                    <div className="d-flex flex-stack mb-3">
                    {
                        (!showPrice && <div className="slice-weight-wrapper">
                            <IonCardSubtitle className="slice-weight content-color font-sm">
                                <span>{formatNumber(slice_props.weight)}</span>
                                <span>kg</span>
                            </IonCardSubtitle>
                        </div>)
                    }
                    {
                        (showPrice && <div className="slice-price-wrapper">
                            <IonCardSubtitle className="slice-price title-color font-sm">
                                <span>{formatNumber(slice_props.price)}</span>
                                <small className="ms-1">Fcfa</small>
                            </IonCardSubtitle>
                        </div>)
                    }
                    </div>
                </div>
                <div className="slice-widget-buttons-wrapper">
                    <IonButton
                        type="button"
                        buttonType="button"
                        size="small"
                        fill="clear"
                        onClick={handleRemove}
                    >
                        <MinusIcon />
                    </IonButton>
                    <input
                        type="number"
                        // placeholder="0"
                        value={getQuantity(slice_props.id)}
                        min="0"
                        readOnly={true}
                    />
                    <IonButton
                        type="button"
                        buttonType="button"
                        size="small"
                        fill="clear"
                        onClick={handleAddToCart}
                    >
                        <PlusIcon />
                    </IonButton>
                </div>
            </IonCardContent>
        </IonCard>
    );
};

export default Slice;
