import axios from "axios";

export default axios.create({
  baseURL: "https://api.profood-app.com/api/"
    // process.env.NODE_ENV === "production"
    // ? "https://api.profood-app.com/api/"
    // // : "http://localhost:8000/api/",
    // // : "http://192.168.1.4:8000/api/",
    // // : "https://api.profood-app.com/api/",
    //   : "http://localhost:8000/api/",

        // withCredentials: true,
        // headers: {
        //   Accept: "*/*",
        //   "Content-Type": "application/json",
        //   "Access-Control-Allow-Origin" : 'http://127.0.0.1:3000'
        // },
});
