import React, { useEffect, useRef } from 'react';

import { IonAlert, useIonRouter,} from '@ionic/react';

import { useTranslation } from 'react-i18next';

import { useConnectionReminderAlertContext } from '../../contexts/ConnectionReminderAlertProvider';

import './ConnectionReminderAlert.css';

/**
 * 
 * @returns 
 */
const ConnectionReminderAlert: React.FC = () => {
    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const alert = useRef<HTMLIonAlertElement>(null);

    /**
     * 
     */
    const { canDismiss, canPresent, setCanDismiss, setCanPresent } = useConnectionReminderAlertContext();

    /**
     * 
     */
    const dismiss = () => {
        alert.current?.dismiss();
    };

    /**
     * 
     */
    const present = () => {
        alert.current?.present();
    };

    /**
     * 
     */
    useEffect(() => {
        if(canDismiss){
            dismiss();
            setCanDismiss(false);
        }
    }, [canDismiss]);

    /**
     * 
     */
    useEffect(() => {
        if(canPresent){
            present();
            setCanPresent(false);
        }
    }, [canPresent]);

    /**
     * 
     */
    const router = useIonRouter();

    /**
     * 
     */
    return (
        <IonAlert
            translucent={true}
            ref={alert}
            id='connectionReminderAlert'
            header="Information"
            message={`${t("Vous devez d'abord vous connecter avant de pouvoir ajouter des produits au panier")}.`}
            buttons={[
                {
                    text: t('Annuler'),
                    role: 'cancel',
                    cssClass: 'alert-btn-cancel',
                    handler: () => {
                        // setHandlerMessage('Alert canceled');
                        dismiss();
                    },
                },
                {
                    text: t('Se connecter'),
                    role: 'confirm',
                    cssClass: 'alert-btn-confirm alert-btn-signin',
                    handler: () => {
                        // setHandlerMessage('Alert confirmed');
                        dismiss();
                        router.push("/signin", "forward", "push");
                    },
                },
            ]}
        >
        </IonAlert>
    );
};

export default ConnectionReminderAlert;
