import React, { useCallback } from "react";

import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle
} from "@ionic/react";

import { useTranslation } from "react-i18next";

import useGoTo from "../hooks/useGoTo";
import { formatNumber } from "../../helpers/AssetHelpers";
import { useLoadingSpinnerContext } from "../../contexts/LoadingSpinnerProvider";

import './BoxType.css';

/**
 * 
 */
export interface BoxTypeProps {
    id: number;
    wording: string;
    price: number;
    capacity: number;
    illustration: string;
}

/**
 * 
 * @param boxType 
 * @returns 
 */
const BoxType: React.FC<BoxTypeProps> = (boxType: BoxTypeProps) => {
    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const goTo = useGoTo();

    /**
     * 
     */
    const { setShowSpinner } = useLoadingSpinnerContext();

    /**
     * 
     * @param id 
     */
    const navigate = useCallback((id: number) => {
        setShowSpinner(true);
        setTimeout(() => {
            setShowSpinner(false);
        }, 1000);
		goTo(`/slices/typeBox/${id}`, 'none', 'push');
	}, []);

    return (
        <IonCard className="box-type-widget card translucent-style">
            <div className="box-type-image-wrapper">
                <img
                    className="box-type-image blur-shadow position-bottom"
                    // src={toAbsolutePublicUrl('/media/images/illustrations/boxes/' + boxType.id + '.jpg')}
                    src={boxType.illustration}
                    alt={boxType.wording}
                />
                <img
                    className="box-type-image"
                    // src={toAbsolutePublicUrl('/media/images/illustrations/boxes/' + boxType.id + '.jpg')}
                    src={boxType.illustration}
                    alt={boxType.wording}
                />
            </div>
            <IonCardContent className="box-type-widget-content card-body">
                <div className="box-type-infos">
                    <div className="box-type-title-wrapper">
                        <IonCardTitle className="box-type-title card-title title-color font-md">{boxType.wording}</IonCardTitle>
                    </div>
                    <div className="box-type-slices-number-wrapper">
                        <IonCardSubtitle className="box-type-slices-number content-color font-sm">{boxType.capacity} {t('découpes')}</IonCardSubtitle>
                    </div>
                    <div className='card-footer'>
                        <div className="box-type-price-wrapper font-sm">
                            <span className='box-type-price'>{formatNumber(boxType.price)}</span>
                            <small className='box-type-price-currency'>Fcfa</small>
                        </div>
                        <div className="box-type-widget-buttons-wrapper">
                            <IonButton
                                type='button'
                                buttonType='button'
                                fill='solid'
                                size='small'
                                color='primary'
                                className="box-type-widget-button"
                                onClick={() => navigate(boxType.id)}
                            >
                                <span className="btn-text">{t('Acheter')}</span>
                            </IonButton>
                        </div>
                    </div>
                </div>
            </IonCardContent>
		</IonCard>
    );
};

export default BoxType;
