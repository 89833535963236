import React from 'react';

import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react';

import Header from './layout/Header';
import Footer from './layout/Footer';
import ContentBody from './ContentBody';
import CartMenuTabsProvider from './components/contexts/CartMenuTabsProvider';
import useToggleTabBar from '../../components/hooks/useToggleTabBar';

import './CartPage.css';

/**
 * 
 * @returns 
 */
const CartPage: React.FC = () => {
    /**
     * 
     */
    const toggleTabBar = useToggleTabBar();

    /**
     * 
     */
    useIonViewDidEnter(() => {
        toggleTabBar(false);
    });

    /**
     * 
     */
    // useIonViewDidLeave(() => {
    //     toggleTabBar(true);
    // });
    return (
        <CartMenuTabsProvider>
            <IonPage id="cartPage">
                <Header />
                <IonContent
                    id='cartPageContent'
                    className='ion-padding'
                >
                    <ContentBody />
                </IonContent>
                <Footer />
            </IonPage>
        </CartMenuTabsProvider>
    );
};

export default CartPage;
