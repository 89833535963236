import React from "react";

import { IonContent, IonPage, useIonViewDidEnter } from "@ionic/react";

import Body from "./layout/Body";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import useToggleTabBar from "../../components/hooks/useToggleTabBar";
import OrdersMenuTabsProvider from "./components/contexts/OrdersMenuTabsProvider";

import './OrdersPage.css';

/**
 * 
 * @returns 
 */
const OrdersPage: React.FC = () => {
    /**
     * 
     */
    const toggleTabBar = useToggleTabBar();

    /**
     * 
     */
    useIonViewDidEnter(() => {
        toggleTabBar(false);
    });

    /**
     * 
     */
    // useIonViewDidLeave(() => {
    //     toggleTabBar(true);
    // });
    return(
        <OrdersMenuTabsProvider>
            <IonPage>
                <Header/>
                <IonContent>
                    <Body />
                </IonContent>
                <Footer />
            </IonPage>
        {/* // <SuccessfulOrder /> */}
        </OrdersMenuTabsProvider>
    );
};

export default OrdersPage;
