import React from 'react';

import { IonCol, IonGrid, IonRow } from '@ionic/react';

import Category, { CategoryProps } from './Category';

/**
 * 
 */
export interface CategoryPropsList {
    categoryPropsList: CategoryProps[];
}

/**
 * 
 * @param param0 
 * @returns 
 */
const CategoryList: React.FC<CategoryPropsList> = ({categoryPropsList}: CategoryPropsList) => {

    return (
        <IonGrid className="category-list-widget">
            <IonRow>
            {
                categoryPropsList.map((category_props) => (
                    <IonCol
                        size="12" size-sm="6" size-md='6' size-lg='4' size-xl='4'
                        key={category_props.id}
                    >
                        <Category {...category_props} />
                    </IonCol>
                ))
            }
            </IonRow>
        </IonGrid>
    );
};

export default CategoryList;
