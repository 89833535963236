import React from 'react';

import {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail,
    useIonViewDidEnter
} from '@ionic/react';

import Header from './layout/Header';
import CategoryList from '../../components/categories/CategoryList';
import useToggleTabBar from '../../components/hooks/useToggleTabBar';
import { useDataContext } from '../../contexts/DataProvider';

import './CategoriesPage.css';

/**
 * 
 * @returns 
 */
const CategoriesPage: React.FC = () => {
    /**
     * 
     */
    const toggleTabBar = useToggleTabBar();

    /**
     * 
     */
    useIonViewDidEnter(() => {
        toggleTabBar(false);
    });

    /**
     * 
     */
    const { categoriesProps } = useDataContext();

    /**
     * 
     * @param event 
     */
    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setTimeout(() => {
            // Any calls to load data go here
            window.location.reload();
            event.detail.complete();
        }, 2000);
    };
    return (
        <IonPage id='categoriesPage'>
            <Header />
            <IonContent
                id='categoriesPageContent'
                className='ion-padding'
            >
                <IonRefresher
                    slot='fixed'
                    onIonRefresh={handleRefresh}
                >
                    <IonRefresherContent />
                </IonRefresher>
                <div className='wrapper'>
                    <CategoryList categoryPropsList={categoriesProps} />
                </div>
                <div className="elfsight-app-ff865515-63a6-491f-97f8-c6fa0c5b8afa"></div>
            </IonContent>
        </IonPage>
    );
};

export default CategoriesPage;
