import React from 'react';
import { createRoot } from 'react-dom/client';

import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';

import LoadingSpinnerProvider from './contexts/LoadingSpinnerProvider';
import ThemeModeProvider from './contexts/ThemeModeProvider';
import DataProvider from './contexts/DataProvider';
import UserInfosProvider from './contexts/UserInfosProvider';
import CartProvider from './pages/cart/components/contexts/CartProvider';
import OrdersProvider from './pages/orders/components/contexts/OrdersProvider';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

/**
 * 
 */
const container = document.getElementById('root');

/**
 * 
 */
const root = createRoot(container!);

/**
 * 
 */
root.render(
    <React.StrictMode>
        <LoadingSpinnerProvider>
            <I18nextProvider i18n={i18n}>
                <ThemeModeProvider>
                    <DataProvider>
                        <UserInfosProvider>
                            <CartProvider>
                                <OrdersProvider>
                                    <App />
                                </OrdersProvider>
                            </CartProvider>
                        </UserInfosProvider>
                    </DataProvider>
                </ThemeModeProvider>
            </I18nextProvider>
        </LoadingSpinnerProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
