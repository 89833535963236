import React, { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import api from "../api/api";
import DataContext, { DataContextType } from "./DataContext";
import { useLoadingSpinnerContext } from "./LoadingSpinnerProvider";
import { BoxTypeProps } from "../components/box/BoxType";
import { CategoryProps } from "../components/categories/Category";
import { SliceProps } from "../components/slices/Slice";
import useToast from "../components/hooks/useToast";
import { LocalityInfo } from "../pages/cart/components/types";

/**
 * 
 */
interface Props {
    children: React.ReactNode;
}

/**
 * 
 * @returns 
 */
export const useDataContext = () => useContext(DataContext);

/**
 * Create the provider component
 *
 * @param param0 
 * @returns 
 */
const DataProvider = ({ children }: Props) => {
    /**
     * 
     */
    const { t } = useTranslation();

    /**
     * 
     */
    const [boxTypesProps, setBoxTypesProps] = useState<BoxTypeProps[]>([]);
    const [categoriesProps, setCategoriesProps] = useState<CategoryProps[]>([]);
    const [localities, setLocalities] = useState<LocalityInfo[]>([]);
    const [slicesProps, setSlicesProps] = useState<SliceProps[]>([]);

    /**
     * 
     */
    const { setShowSpinner } = useLoadingSpinnerContext();

    /**
     * 
     */
    const showToast = useToast();

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchBoxTypesProps = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        if(showSpinner){
            setShowSpinner(true);
        }
        api.get('/get-box-types').then((res) => {
            setBoxTypesProps(res.data);
            setTimeout(() => {
                if(showSpinner){
                    setShowSpinner(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinner(false);
            showToast(`${t('Une erreur est survenue ! Veuillez réessayer ou contacter Profood')}.`);
            console.log(error);
        });
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchCategoriesProps = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        if(showSpinner){
            setShowSpinner(true);
        }
        api.get('/get-categories-with-slices-count').then((res) => {
            setCategoriesProps(res.data);
            setTimeout(() => {
                if(showSpinner){
                    setShowSpinner(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinner(false);
            showToast(`${t('Une erreur est survenue ! Veuillez réessayer ou contacter Profood')}.`);
            console.log(error);
        });
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchLocalities = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        if(showSpinner){
            setShowSpinner(true);
        }
        api.get('/get-localites-with-full-info').then((res) => {
            setLocalities(res.data);
            setTimeout(() => {
                if(showSpinner){
                    setShowSpinner(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinner(false);
            showToast(`${t('Une erreur est survenue ! Veuillez réessayer ou contacter Profood')}.`);
            console.log(error);
        });
    }, [setShowSpinner, showToast]);

    /**
     * 
     * @param showSpinner 
     * @param spinnerTime 
     */
    const fetchSlicesProps = useCallback((showSpinner: boolean = true, spinnerTime: number = 2000) => {

        if(showSpinner){
            setShowSpinner(true);
        }
        api.get('/get-slices').then((res) => {
            setSlicesProps(res.data);
            setTimeout(() => {
                if(showSpinner){
                    setShowSpinner(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinner(false);
            showToast(`${t('Une erreur est survenue ! Veuillez réessayer ou contacter Profood')}.`);
            console.log(error);
        });
    }, [setShowSpinner, showToast]);

    /**
     * 
     */
    const fetchData = useCallback(() => {
        fetchBoxTypesProps(false);
        fetchCategoriesProps(false);
        fetchSlicesProps(false);
        fetchLocalities(true, 2000);
    }, [fetchBoxTypesProps, fetchCategoriesProps, fetchLocalities, fetchSlicesProps]);

    /**
     * 
     */
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /**
     * Define the context value
     */
    const contextValue : DataContextType = {
        boxTypesProps,
        categoriesProps,
        localities,
        slicesProps,
        fetchBoxTypesProps,
        fetchCategoriesProps,
        fetchData,
        fetchLocalities,
        fetchSlicesProps,
        setBoxTypesProps,
        setCategoriesProps,
        setLocalities,
        setSlicesProps
    };
    return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

export default DataProvider;
